<script>
/**
 * A Generic Banner component that displays a title, subtitle, slot content and an illustration.
 */
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="banner">
    <div class="banner__content">
      <h3
        v-if="subtitle"
        class="banner__subtitle"
      >
        {{ subtitle }}
      </h3>
      <h2 class="banner__title">
        {{ title }}
      </h2>
      <slot />
    </div>
    <figure class="banner__illustration">
      <img
        src="@/assets/images/complete-form.png"
        alt="An illustration of forms within a web browser"
      >
    </figure>
  </div>
</template>

<style lang="scss" scoped>
  .banner {
    padding: 1.5rem;
    border: 1px solid $axon;
    border-radius: 2px;
    margin: 1.25rem 0 1.75rem;
    background: $white-matter;

    display: grid;
    grid-template: auto / auto auto;
    justify-content: space-between;

    &__content {
      grid-column: 1;
      padding-right: 1rem;
      @include text-style('interface', 'small', 'regular');

      > p:first-of-type {
        margin: 0;
      }
    }

    &__illustration {
      grid-column: 2;
      /* Using CSS custom properties we can create an "API" for our scoped components.
       * In this case, we expose the --illustration-width property so that it can be
       * modified where this component is implemented.
       * After design review we ended up not varying the width but we can still keep this functionality. */
      width: var(--illustration-width, 16rem);
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 14rem;
        max-width: 100%;
      }
    }

    &__title {
      margin: 0;
      margin-bottom: .5rem;
      @include text-style('title', 'large', 'bold');
      line-height: 1.125;
    }

    &__subtitle {
      margin: 0;
      color: $hillcock;
      @include text-style('paragraph', 'small', 'bold');
      text-transform: uppercase;
    }
  }
</style>
