import DELETE_VISIT_INSTANCE_MUTATION from '@/graphql/visits/DeleteVisitInstanceMutation.graphql'
import GET_PARTICIPANT_VISITS_QUERY from '@/graphql/participants/GetStudyParticipantVisitsQuery.graphql'
import addNotification from '@/mixins/addNotification'
import { logError } from '@/utils/logging'

export default {
  mixins: [addNotification],
  data() {
    return {
      updatingVisitInstance: false
    }
  },
  methods: {
    clearVisitStartDate(visitInstance) {
      if (visitInstance && visitInstance.id) {
        this.deleteVisitInstance(visitInstance.id)
      }
    },

    /**
     * Delete the visit instance for the provided visit instance id.
     * @param {string} visitInstanceId
     */
    deleteVisitInstance(visitInstanceId) {
      this.updatingVisitInstance = true
      return this.$apollo.mutate({
        mutation: DELETE_VISIT_INSTANCE_MUTATION,
        variables: { visitInstanceId: visitInstanceId },
        refetchQueries: [
          {
            query: GET_PARTICIPANT_VISITS_QUERY,
            variables: {
              participantId: this.$route.params.participantId,
              studyId: this.$route.params.studyId
            }
          }
        ],
        awaitRefetchQueries: true
      })
        .then(() => {
          this.$router.push({
            name: 'visitSchedule',
            params: { ...this.$route.params }
          })
        })
        .catch(error => {
          logError(error, 'deleteVisitInstance mutation:')
          this.addErrorNotification('There was an error clearing the visit instance.')
        })
        .finally(() => {
          this.updatingVisitInstance = false
        })
    }
  }
}
