<template>
  <Modal
    v-loading="isLoading"
    class="complete-visit-modal"
    @close="$emit('close')"
  >
    <template v-slot:title>
      Approve Visit
    </template>
    <template v-slot:content>
      <div class="confirm-checkbox">
        <BfCheckbox
          id="confirmApproveVisit"
          v-model="confirmChecked"
          class="complete-visit-checkbox"
          label="I confirm that I have reviewed the data entries for this visit and determined that they are
              complete, accurate, and consistent with source documents, if applicable."
          :required="true"
        />
      </div>
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
      >
        <BfInput
          v-model="password"
          :error="passwordError || errors[0]"
          type="password"
          label="Re-enter Password"
          @input="passwordError = null"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
      >
        <BfDatePicker
          v-model="visitEndDate"
          :error="errors[0]"
          class="datepicker"
          label="Visit End Date"
          :disable-before="visit.visitInstance.startDate"
        />
      </ValidationProvider>
    </template>
    <template v-slot:actions>
      <el-button
        :disabled="!canApproveVisit"
        type="primary"
        @click="handleApproveVisit"
      >
        Approve Visit
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfInput from '@/components/BfInput/BfInput'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'
import Auth from '@aws-amplify/auth'
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import COMPLETE_VISIT_MUTATION_V2 from '@/graphql/visits/CompleteVisitMutationV2.graphql'
import { formatDateForAPI } from '@/utils/date'
import { propEq } from 'ramda'
import { logError } from '@/utils/logging'

export default {
  components: {
    Modal,
    BfInput,
    BfDatePicker,
    BfCheckbox
  },
  props: {
    visit: {
      type: Object,
      default: () => {}
    }
  },
  apollo: {
    userEmail: {
      query: GET_USER_QUERY,
      update: data => data.getUser.emailAddress,
      error (error) {
        logError(error, 'CompleteVisitModal.vue user query')
      }
    }
  },
  data() {
    return {
      isLoading: false,
      password: '',
      visitEndDate: '',
      confirmChecked: false,
      passwordError: null
    }
  },
  computed: {
    canApproveVisit() {
      return this.confirmChecked && this.password && this.visitEndDate
    }
  },
  methods: {
    // displays a confirmation modal for completing a visit
    handleApproveVisit() {
      // the back end verifies that a new valid user token is in the auth header
      this.isLoading = true
      Auth.signIn(this.userEmail, this.password)
        .then(() =>
          this.$apollo.mutate({
            mutation: COMPLETE_VISIT_MUTATION_V2,
            variables: {
              visitId: this.visit.visitId,
              endDate: formatDateForAPI(this.visitEndDate)
            }
          })
        )
        .then(response => {
          this.$router.push({
            name: 'visitSchedule',
            params: {
              ...this.$route.params
            }
          })
        })
        .catch(error => {
          if (propEq('code', 'NotAuthorizedException', error)) {
            this.passwordError = 'Incorrect Password.'
          } else {
            logError(error, 'CompleteVisitModal.vue complete visit mutation')
          }
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
<style lang="scss" scoped>
.complete-visit-modal {
  /deep/ .field-wrap {
    width: 64%;

    &:first-of-type {
      width: 100%;
      padding-right: 2rem;
    }
  }
  .datepicker {
    margin-top: 1rem;
  }
}
</style>
