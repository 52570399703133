/**
 * Add notification message to display messages to users if the current view contains a <NotificationTray> component.
 * The <NotificationTray> component should include a ref of "notifications" to support smooth scroll.
 */
export default {
  data() {
    return {
      notifications: []
    }
  },
  methods: {
    /**
     * Add a success message.
     * @param {string} message
     */
    addSuccessNotification(message) {
      this.createNotification('success', message)
      this.scrollToNotifications()
    },

    /**
     * Add a warning message.
     * @param {string} message
     */
    addWarningNotification(message) {
      this.createNotification('info', message)
      this.scrollToNotifications()
    },

    /**
     * Add an error message.
     * @param {string} message
     */
    addErrorNotification(message) {
      this.createNotification('error', message)
      this.scrollToNotifications()
    },

    /**
     * Create a notification of a specified type.
     * @param {string} type
     * @param {string} message
     */
    createNotification(type, message) {
      this.notifications.push({
        id: this.notifications.length,
        type: type || 'error',
        title: '',
        message: message || 'An unexpected error occurred. Please try again.'
      })
    },

    /**
     * If the notification area exists, smooth scroll back up to notification area
     */
    scrollToNotifications() {
      if (this.$refs.notifications) {
        this.$refs.notifications.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }
  }
}
