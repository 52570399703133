<script>
/**
 * A Banner that nudges the user to complete a CRF that must be completed before continuing to the rest of the visit.
 */
import Banner from './Banner'
import openForm from '@/mixins/openForm'

export default {
  components: { Banner },
  mixins: [ openForm ],
  props: {
    requiredForms: {
      type: Array,
      required: true
    }
  },
  methods: {
    openRequiredForm(form) {
      // visitInstanceId added to the form object within getBlockingForms util function
      this.openFormInstance(form, form.visitInstanceId)
    }
  }
}
</script>

<template>
  <Banner
    title="Required Forms"
    class="required-forms-banner"
    @open="openRequiredForm"
  >
    <p>Please complete the following forms to continue with the visits in this visit window.</p>
    <ul>
      <li
        v-for="form in [requiredForms[0]]"
        :key="form.id"
        @click="openRequiredForm(form)"
      >
        <SvgIcon
          class="warning-icon"
          name="warning"
          height="18"
          width="18"
        />
        {{ form.visitLabel }}: {{ form.formVersion.title }}
      </li>
    </ul>
  </Banner>
</template>

<style lang="scss">
.required-forms-banner {
  ul {
    padding: 0;

    li {
      padding: .5rem 0;
      list-style-type: none;
      cursor: pointer;
      font-weight: 500;
      color: $dopamine;
      display: flex;
      align-items: center;

      &:first-child {
        padding-top: 0;
      }

      .warning-icon {
        fill: $warning;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}
</style>
