import UPDATE_VISIT_INSTANCE_MUTATION from '@/graphql/visits/UpdateVisitInstanceMutation.graphql'
import addNotification from '@/mixins/addNotification'
import { formatDateForAPI } from '@/utils/date'
import { logError } from '@/utils/logging'

export default {
  mixins: [addNotification],
  data() {
    return {
      updatingVisitInstance: false
    }
  },
  methods: {
    modifyVisitStartDate(visitInstance, newDate) {
      if (visitInstance && visitInstance.id) {
        this.updateVisitInstance(visitInstance.id, formatDateForAPI(newDate))
      }
    },

    /**
     * Update the VisitInstance.
     * Right now, only the visit start date can be edited.
     * The start date will be cleared when not provided.
     *
     * @param {string} visitInstanceId - the id of the visit instance to update
     * @param {string} newDate - a new date string to set the visit start date
     * @returns {Promise}
     */
    updateVisitInstance(visitInstanceId, newDate = null) {
      this.updatingVisitInstance = true
      return this.$apollo.mutate({
        mutation: UPDATE_VISIT_INSTANCE_MUTATION,
        variables: {
          visitInstanceId: visitInstanceId,
          startDate: newDate
        }
      })
        .catch(error => {
          logError(error, 'updateVisitInstance mutation:')
          this.addErrorNotification('There was an error saving the updated visit instance.')
        })
        .finally(() => {
          this.updatingVisitInstance = false
        })
    }
  }
}
