<template>
  <div :class="['check-list-item', { 'check-list-item--selected': isSelected }]">
    <label>
      <input
        type="checkbox"
        v-bind="$attrs"
        @change="handleChange"
      >
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'values',
    event: 'change'
  },
  props: {
    values: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    isSelected() {
      return this.values.includes(this.value)
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('change', event.target.checked
        ? this.values.concat(this.value)
        : this.values.filter(val => val !== this.value)
      )
    }
  }
}
</script>

<style lang="scss">
.check-list-item {
  border: 1px solid $axon;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 .875rem;

  &--selected {
    border: 2px solid $black;
  }

  &:hover:not(&--selected) {
    border-color: $cortex;
  }

  & + & {
    margin-top: .5rem;
  }

  input {
    cursor: pointer;
    appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
  }

  &--selected {
    &::before {
      content: url(../../assets/images/checkmark.svg);
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: .5rem;
    }
  }
}
</style>
