<template>
  <Modal
    class="add-forms-modal"
    title="Select forms to add to visit"
    fix-header-and-footer
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div>
        <SearchBox
          v-model="searchString"
          class="add-forms-modal__search-box"
          placeholder="Search"
        />
        <template v-if="forms.length">
          <CheckListItem
            v-for="form in formsToDisplay"
            :key="form.id"
            v-model="selectedForms"
            :value="form.id"
          >
            {{ form.title }}
          </CheckListItem>
        </template>
        <template v-else-if="formsFromVisits.length">
          <div
            v-for="formVisit in formsFromVisits"
            :key="getFormsForDisplayKey(formVisit)"
          >
            <h3>{{ getFormsForDisplayKey(formVisit) }}</h3>
            <CheckListItem
              v-for="form in getFormsForDisplayValue(formVisit)"
              :key="form.id"
              v-model="selectedForms"
              :value="form.id"
            >
              {{ form.title }}
            </CheckListItem>
          </div>
        </template>
        <EmptyState
          v-else
          image="form"
          title="No Forms Available"
          subtitle="There aren’t any forms available to add to this visit"
          image-above-text
        />
      </div>
    </template>
    <template v-slot:actions>
      <el-button
        :disabled="!selectedForms.length"
        type="primary"
        @click="handleAddFormsClick"
      >
        Add Forms
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import SearchBox from '@/components/SearchBox/SearchBox'
import CheckListItem from '@/components/CheckListItem/CheckListItem'
import EmptyState from '@/components/EmptyState/EmptyState'
import { logError } from '@/utils/logging'
import addOptionalForms from '@/mixins/addOptionalForms'
import { compose, prop, sortBy, toLower, propOr } from 'ramda'
import { getVisitLabel } from '@/utils/visit'
import { VisitStatus } from '@/utils/constants'

export default {
  components: {
    Modal,
    CheckListItem,
    SearchBox,
    EmptyState
  },
  mixins: [
    addOptionalForms
  ],
  props: {
    // take an array of forms OR an array of visits to extract forms from
    forms: {
      type: Array,
      default: () => []
    },
    visits: {
      type: Array,
      default: () => []
    },
    visitWindowVisits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedForms: [],
      searchString: ''
    }
  },
  computed: {
    canApproveVisit() {
      return this.confirmChecked && this.password && this.visitEndDate
    },
    formsFromVisits() {
      const visitForms = []

      if (this.visits.length) {
        this.visits.reduce((forms, visit) => {
          if (forms === undefined) {
            forms = {}
          }

          const unaddedForms = visit?.visitTemplateForms
            .filter(visitTemplateForm =>
              !visit.formInstances.some(formInstance => formInstance.formVersion.code === visitTemplateForm.code)
            )

          if (unaddedForms.length) {
            const targetVisit = this.visitWindowVisits.find(vwv => {
              return vwv.visitInstance.id === visit.visitInstance.id
            })
            forms[getVisitLabel(targetVisit)] = unaddedForms
          }

          // Since parentheses are higher in the sort order than alphanumeric characters, we need to filter them out
          // and sort them separately from the other forms in the list.
          for (const [key, value] of Object.entries(forms)) {
            let alphanumericForms = value.filter(form => !form.title.startsWith('('))
            let parentheticalForms = value.filter(form => form.title.startsWith('('))

            // Sort each list of forms alphabetically.
            alphanumericForms = sortBy(compose(toLower, prop('title')))(alphanumericForms)
            parentheticalForms = sortBy(compose(toLower, prop('title')))(parentheticalForms)

            // Append the parenthetical forms to the end of the alphanumeric forms
            // and then filter forms based on the search term
            const sortedForms = alphanumericForms.concat(parentheticalForms).filter(form =>
              form.title.toLowerCase().includes(this.searchString.toLowerCase())
            )

            visitForms.push({
              [key]: sortedForms
            })
          }
        }, {})
      }

      return visitForms
    },
    formsToDisplay() {
      // Since parentheses are higher in the sort order than alphanumeric characters, we need to filter them out
      // and sort them separately from the other forms in the list.
      let alphanumericForms = this.forms.filter(form => !form.title.startsWith('('))
      let parentheticalForms = this.forms.filter(form => form.title.startsWith('('))

      // Sort each list of forms alphabetically.
      alphanumericForms = sortBy(compose(toLower, prop('title')))(alphanumericForms)
      parentheticalForms = sortBy(compose(toLower, prop('title')))(parentheticalForms)

      // Append the parenthetical forms to the end of the alphanumeric forms
      // and then filter forms based on the search term
      return alphanumericForms.concat(parentheticalForms).filter(form =>
        form.title.toLowerCase().includes(this.searchString.toLowerCase())
      )
    }
  },
  methods: {
    handleAddFormsClick() {
      let visitInstanceId = ''
      if (this.visits?.find(v => ['SC', 'BL', 'BL (Clinic)'].includes(v.visitTemplate.code)) !== undefined) {
        // Screening & Baseline visit
        const reopenedVisitInstance = this.visits
          .flatMap(visit => propOr([], 'visitInstance', visit))
          .find(visit => visit.status === VisitStatus.REOPENED)
        if (reopenedVisitInstance) {
          // Look for `reopened` visit, if any then use that visit instance when adding forms
          visitInstanceId = reopenedVisitInstance.id
        } else {
          // Find `in_progress` visit and use that visit instance when adding forms
          visitInstanceId = this.visits.find(visit => {
            return visit.visitInstance.status === VisitStatus.IN_PROGRESS
          })?.visitInstance.id
        }
      } else {
        // Find corresponding `visitInstance` for the selected form(s) by matching
        // with `visitTemplateForms` array
        visitInstanceId = this.visits.find(v => {
          return v.visitTemplateForms.find(vtf => {
            return this.selectedForms.includes(vtf.id)
          })
        }).visitInstance?.id
      }

      this.addOptionalForms(this.selectedForms, visitInstanceId)
        .then(() => this.$emit('close'))
        .catch((e) => logError(e, 'create form instances mutation'))
    },

    getFormsForDisplayKey(form) {
      return Object.keys(form)[0]
    },

    getFormsForDisplayValue(form) {
      return Object.values(form)[0]
    }
  }
}
</script>
<style lang="scss">
.add-forms-modal {
  &__search-box {
    margin-bottom: 1rem;
  }
}
</style>
